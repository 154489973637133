.euiFormLabel,
.euiFormLegend {
  font-weight: 400;
}

.euiSwitch .euiSwitch__body {
  background-color: #6ee092;
}

.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
  background-color: #b9bfca;
}

.euiSwitch .euiSwitch__icon,
.euiSwitch .euiSwitch__icon--checked {
  fill: transparent;
}
