/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  font-size: 15px;
}

* {
  box-sizing: border-box;
}

.euiTitle {
  font-family: 'Nunito Sans', sans-serif;
}

html,
body,
#__next {
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
}

.euiSideNav__mobileToggle {
  display: none !important;
}

.euiHeader {
  z-index: 1001;
}

.euiHeader.euiHeader--dark .euiBreadcrumbs {
  filter: invert(1);
}

.euiBody--headerIsFixed .euiFlyout,
.euiBody--headerIsFixed .euiCollapsibleNav {
  --offset: 48px;

  top: var(--offset);
  height: calc(100% - var(--offset));
  z-index: 1000;
}

/* .euiPage {
  margin-top: 50px !important;
} */

@media (max-width: 991px) {
  .euiBody--headerIsFixed .euiFlyout,
  .euiBody--headerIsFixed .euiCollapsibleNav {
    --offset: 96px;
  }

  /* .euiPage {
    margin-top: 100px !important;
  } */
}

@media (min-width: 992px) {
  /* .euiBody--collapsibleNavIsDocked {
    transition: none !important;
  } */

  .euiBody--collapsibleNavIsDocked .euiCollapsibleNav {
    animation: none !important;
  }
}

body.euiBody--collapsibleNavIsDocked {
  padding-left: 320px !important;
}

.euiButton {
  border-radius: 40px !important;
}

.euiButton .euiButton__content {
  padding: 0 24px !important;
}
.euiFilterButton {
  font-weight: 500 !important;
}

.euiFilterButton.euiFilterButton-hasActiveFilters {
  font-weight: 800 !important;
}

.euiAvatar.euiAvatar--user {
  font-weight: 600;
}

.euiFormControlLayout__childrenWrapper {
  background-color: white;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed
  .euiFormControlLayout__prepend:first-child {
  min-width: 5em;
}
